/* purgecss start ignore */
/*!rtl:begin:ignore*/
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min, .irs-max {
  position: absolute;
  display: block;
  cursor: default;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed;
}

.irs-disabled {
  opacity: 0.4;
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9;
}

.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}

.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453;
}

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: white;
  background: linear-gradient(to bottom, #ddd -50%, white 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
}

.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66, 139, 202, 0.5);
}

.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
  border-radius: 30px;
}

.irs--big .irs-handle.state_hover, .irs--big .irs-handle:hover {
  border-color: rgba(0, 0, 0, 0.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
}

.irs--big .irs-min, .irs--big .irs-max {
  top: 0;
  padding: 1px 5px;
  color: white;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
}

.irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single {
  color: white;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  border-radius: 3px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern {
  height: 55px;
}

.irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
}

.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209, 214, 224, 0.5);
}

.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: white;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
}

.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
}

.irs--modern .irs-handle.state_hover, .irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
}

.irs--modern .irs-handle.state_hover > i:nth-child(1), .irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3), .irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min, .irs--modern .irs-max {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: white;
  background-color: #d1d6e0;
  border-radius: 5px;
}

.irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: white;
  border-radius: 5px;
}

.irs--modern .irs-from:before, .irs--modern .irs-to:before, .irs--modern .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: black;
  border-radius: 2px;
}

.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5);
}

.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2;
}

.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-handle.state_hover, .irs--sharp .irs-handle:hover {
  background-color: black;
}

.irs--sharp .irs-handle.state_hover > i:first-child, .irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: black;
}

.irs--sharp .irs-min, .irs--sharp .irs-max {
  color: white;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: 0.4;
  background-color: #a804b2;
  border-radius: 2px;
}

.irs--sharp .irs-from, .irs--sharp .irs-to, .irs--sharp .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: white;
  border-radius: 2px;
}

.irs--sharp .irs-from:before, .irs--sharp .irs-to:before, .irs--sharp .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
}

.irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #006cfa;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5);
}

.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min, .irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #006cfa;
  color: white;
  border-radius: 4px;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #006cfa;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede;
}

.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: black;
}

.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede;
}

.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid black;
  background-color: white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover, .irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min, .irs--square .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.irs--square .irs-from, .irs--square .irs-to, .irs--square .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: black;
  color: white;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

/*!rtl:end:ignore*/
/* purgecss end ignore */
.slider-container .slide .card {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
.slider-container .slide .card .card-body {
  background-color: #fff;
  padding: 2em 2em 4em;
}
.slider-container .slide .card .card-body .badges {
  margin-bottom: 1.2em;
}
.slider-container .slide .card .card-body .badges .badge {
  padding: 0.3em 1.5em;
  font-size: 1.5em;
}
.slider-container .slide .card .card-body h4 {
  color: #0E2D52;
}
.slider-container .slide .card .card-footer {
  background-color: #0E2D52;
  text-align: center;
  color: #fff;
  padding: 1.2em 0;
  border-radius: 0;
}
.slider-container .slide .card .card-footer span {
  line-height: 1.5em;
  vertical-align: middle;
}
.slider-container .slide .card .card-footer span.price {
  color: #FCAC2F;
  font-size: 1.5em;
  font-weight: bold;
}
@media (max-width: 767px) {
  .slider-container .slide .card .card-body {
    padding: 3em 3em 6em;
  }
  .slider-container .slide .card .card-body .badges .badge {
    font-size: 2.7em;
  }
  .slider-container .slide .card .card-body h4 {
    font-size: 3.2em;
  }
  .slider-container .slide .card .card-footer span {
    font-size: 2.2em;
  }
  .slider-container .slide .card .card-footer span.price {
    color: #FCAC2F;
    font-size: 2.8em;
    font-weight: bold;
  }
}
@media (max-width: 1500px) {
  .slider-container .tparrows {
    display: none;
  }
}

.slider-section {
  position: relative;
  z-index: 0;
}
.slider-section .slide {
  position: relative;
  height: 720px;
}
.slider-section .slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slider-section .slide .slide-infos {
  position: absolute;
  width: 70%;
  top: 200px;
}
.slider-section .slide .slide-infos .card {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  box-shadow: #000941 0px 33px 73px 0px;
  color: #7b849c;
  line-height: 24px;
  text-align: left;
  display: inline-block;
  border-radius: 0;
  box-shadow: none;
  border: 0;
  min-width: 400px;
}
.slider-section .slide .slide-infos .card .card-body {
  padding: 2em;
}
.slider-section .slide .slide-infos .card .card-body .badges {
  margin-bottom: 1.2em;
}
.slider-section .slide .slide-infos .card .card-body .badges .badge {
  padding: 0.3em 1.5em;
  font-size: 1.2em;
}
.slider-section .slide .slide-infos .card .card-body h4 {
  color: #0E2D52;
}
.slider-section .slide .slide-infos .card .card-footer {
  background-color: #0E2D52;
  text-align: center;
  color: #fff;
  padding: 1.2em 0;
  border-radius: 0;
}
.slider-section .slide .slide-infos .card .card-footer span {
  line-height: 1.5em;
  vertical-align: middle;
}
.slider-section .slide .slide-infos .card .card-footer span.price {
  color: #FCAC2F;
  font-size: 1.5em;
  font-weight: bold;
}
@media (max-width: 1199.98px) {
  .slider-section .slide .slide-infos {
    top: 150px;
  }
}
@media (max-width: 991.98px) {
  .slider-section .slide .slide-infos {
    top: 100px;
  }
}
@media (max-width: 767.98px) {
  .slider-section .slide {
    height: 650px;
  }
  .slider-section .slide .slide-infos {
    top: 70px;
  }
}
@media (max-width: 575.98px) {
  .slider-section .slide {
    height: 550px;
  }
  .slider-section .slide .slide-infos {
    min-width: 350px;
    top: 50px;
  }
}

/* purgecss start igqnore */
.slider-section .owl-carousel, .slider-section .owl-stage-outer, .slider-section .owl-stage, .slider-section .owl-item {
  height: 100%;
}

/* purgecss end ignore */
h3 {
  font-size: 2.4em;
  line-height: 1.4em;
  margin-bottom: 1rem;
}
@media (max-width: 1300px) {
  h3 {
    font-size: 2.2em;
  }
}
@media (max-width: 991px) {
  h3 {
    font-size: 2em;
  }
}

section.search-form-section .container {
  position: relative;
}
section.search-form-section .container .search-bar {
  position: relative;
  top: -100px;
  margin-bottom: -140px;
  background-color: #0E2D52;
  border-radius: 0 0 10px 10px;
}
section.search-form-section .container .search-bar .types .type-btn {
  cursor: pointer;
}
section.search-form-section .container .search-bar .types .type-btn svg {
  fill: #fff;
  height: 45px;
  width: 45px;
}
section.search-form-section .container .search-bar .types .type-btn .divider {
  display: block;
  margin-top: 1.3rem !important;
  margin-bottom: 1rem !important;
}
section.search-form-section .container .search-bar .types .type-btn .divider hr {
  width: 90px;
  height: 2px;
  background-color: #fff;
  margin: auto;
}
section.search-form-section .container .search-bar .types .type-btn .type-text {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2em;
}
section.search-form-section .container .search-bar .types .btn-check:checked + .type-btn svg {
  fill: #FCAC2F;
}
section.search-form-section .container .search-bar .types .btn-check:checked + .type-btn svg path {
  fill: #FCAC2F;
}
section.search-form-section .container .search-bar .types .btn-check:checked + .type-btn .divider hr {
  background-color: #FCAC2F;
}
section.search-form-section .container .search-bar .types .btn-check:checked + .type-btn .type-text {
  color: #FCAC2F;
}
section.search-form-section .container .search-bar .choices {
  background-color: #fff;
  border-radius: 10px;
}
section.search-form-section .container .search-bar .choices .cell:nth-child(1), section.search-form-section .container .search-bar .choices .cell:nth-child(2), section.search-form-section .container .search-bar .choices .cell:nth-child(3), section.search-form-section .container .search-bar .choices .cell:nth-child(4), section.search-form-section .container .search-bar .choices .cell:nth-child(6), section.search-form-section .container .search-bar .choices .cell:nth-child(7) {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
section.search-form-section .container .search-bar .choices .cell:nth-child(8), section.search-form-section .container .search-bar .choices .cell:nth-child(5) {
  min-height: 55px;
  border-bottom: 1px solid #eee;
}
section.search-form-section .container .search-bar .choices .cell .input-group {
  min-height: 50px;
}
section.search-form-section .container .search-bar .choices .cell .input-group select {
  border: 1px solid #fff;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #0E2D52;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/img/arrow-down.svg") no-repeat;
  background-size: 12px;
  background-position: 100% center;
  background-repeat: no-repeat;
  background-color: #fff;
}
section.search-form-section .container .search-bar .choices .cell .btn {
  color: #fff;
  border-radius: 0 10px 0 0;
  text-transform: uppercase;
  font-weight: bold;
}
section.search-form-section .container .search-bar .choices .cell .input-group-text {
  padding: 0;
  border: 0;
  background-color: #fff;
}
section.search-form-section .container .search-bar .bottom-choice input[type=text] {
  border: none;
  box-shadow: none;
  padding: 1.2rem 0.5rem;
  color: #0E2D52;
  font-weight: 600;
}
section.search-form-section .container .search-bar .bottom-choice .input-group .input-group-text {
  width: 100px;
  padding: 0;
  border: 0;
  background-color: #fff;
  font-weight: bold;
  color: #0E2D52;
  font-size: 0.85rem;
  text-transform: uppercase;
}
section.search-form-section .container .search-bar .bottom-choice .input-group .range-slider {
  border: 0;
  padding: 0 0.75rem;
}
@media (max-width: 1200px) {
  section.search-form-section .container .search-bar {
    top: 0;
    margin-bottom: 0;
  }
  section.search-form-section .container .search-bar .choices .cell:nth-child(3), section.search-form-section .container .search-bar .choices .cell:nth-child(7) {
    border-right: 0;
  }
  section.search-form-section .container .search-bar .choices .cell:nth-child(5) {
    border-bottom: 0;
  }
  section.search-form-section .container .search-bar .choices .cell .btn {
    border-radius: 0 0 10px 10px;
  }
}
@media (max-width: 991px) {
  section.search-form-section .container .search-bar .types .type-btn svg {
    fill: #fff;
    height: 30px;
    width: 30px;
  }
  section.search-form-section .container .search-bar .types .type-btn .divider {
    display: block;
    margin-top: 0.9rem !important;
    margin-bottom: 0.4rem !important;
  }
  section.search-form-section .container .search-bar .types .type-btn .divider hr {
    width: 70px;
    height: 2px;
  }
  section.search-form-section .container .search-bar .types .type-btn .type-text {
    font-size: 0.9em;
  }
  section.search-form-section .container .search-bar .choices .cell:nth-child(2), section.search-form-section .container .search-bar .choices .cell:nth-child(4), section.search-form-section .container .search-bar .choices .cell:nth-child(7) {
    border-right: 0;
  }
  section.search-form-section .container .search-bar .choices .cell:nth-child(3) {
    border-right: 1px solid #eee;
  }
  section.search-form-section .container .search-bar .choices .cell:nth-child(5) {
    border-bottom: 0;
  }
  section.search-form-section .container .search-bar .choices .cell .btn {
    border-radius: 0 0 10px 10px;
  }
  section.search-form-section .container .search-bar .choices .cell .form-control, section.search-form-section .container .search-bar .choices .cell .input-group-text {
    font-size: 0.9em;
  }
}
@media (max-width: 576px) {
  section.search-form-section .container .search-bar .types .type-btn .type-text {
    font-size: 0.85em;
  }
}

section.properties-section .property .img-top {
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #FCAC2F;
}

section.citizenship-section {
  position: relative;
  background-color: #0E2D52;
  background-size: cover;
  background-position: bottom;
}
section.citizenship-section:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(14, 45, 82, 0.4);
}
section.citizenship-section .row {
  position: relative;
  z-index: 999;
}
section.citizenship-section .row .section-title {
  color: #FCAC2F;
}
section.citizenship-section .row .section-sub-title {
  position: relative;
}
section.citizenship-section .row .section-sub-title:after {
  position: absolute;
  content: "";
  background-color: #fff;
  height: 3px;
  width: 150px;
  left: 0;
  bottom: -15px;
}
section.citizenship-section .row p {
  font-size: 1.2em;
}

section.articles-section {
  margin-bottom: 50px;
}
section.articles-section .article {
  position: relative;
  padding-bottom: 70px;
  overflow: hidden;
  border-radius: 1em;
}
section.articles-section .article .card-img-top {
  object-fit: cover;
}
section.articles-section .article .card-body {
  background-color: rgba(14, 45, 82, 0.8);
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 15px 0;
}
section.articles-section .article .card-body .article-date {
  position: absolute;
  top: -35px;
  left: 15px;
  color: #fff;
}
section.articles-section .article .card-body .article-date i {
  font-size: 1.2em;
}
section.articles-section .article .card-body .article-views {
  position: absolute;
  top: -35px;
  right: 15px;
  color: #fff;
}
section.articles-section .article .card-body .article-views i {
  font-size: 1.2em;
}
section.articles-section .article .card-body .card-title {
  margin-bottom: 0;
}
section.articles-section .article .card-body .card-title .card-subtitle {
  color: #fff;
}
section.articles-section .article .card-body .card-title h4 {
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
}
section.articles-section .article .card-body .card-text {
  flex: 1;
  color: #fff;
  height: 0;
  margin-top: 15px;
  opacity: 0;
  transition: 450ms;
  -moz-transition: 450ms;
  -webkit-transition: 450ms;
}
section.articles-section .article .card-body .card-text p {
  margin-bottom: 0;
}
section.articles-section .article .card-body .card-text .read-more i {
  font-size: 4em;
}
section.articles-section .article .card-body .more-details hr {
  border-color: #FCAC2F;
}
section.articles-section .article:hover .card-body {
  background-color: rgba(14, 45, 82, 0.8);
  padding-bottom: 15px;
}
section.articles-section .article:hover .card-body .card-title .card-subtitle {
  color: #FCAC2F;
}
section.articles-section .article:hover .card-body .card-text {
  flex: 0;
  height: 50px;
  opacity: 1;
}
section.articles-section .article:hover .card-body .card-text .arrow1::after {
  border-color: transparent;
  border-bottom-color: #FCAC2F;
}
section.articles-section .article:hover .card-body .card-text .arrow1::before {
  background-color: #FCAC2F;
}
section.articles-section .article:hover .card-body .arrow2::after {
  border-color: transparent;
  border-bottom-color: #FCAC2F;
}
section.articles-section .article:hover .card-body .arrow2::before {
  background-color: #FCAC2F;
}

section.about-section .section-title {
  background-color: #0E2D52;
}
section.about-section .section-title h3 {
  color: #fff;
  font-size: 3.7em;
  line-height: 1.3em;
}
section.about-section .section-title img {
  position: absolute;
}
@media (max-width: 1400px) {
  section.about-section .section-title img {
    width: 500px;
  }
}
@media (max-width: 1200px) {
  section.about-section .section-title img {
    position: relative;
    width: 100%;
  }
}
section.about-section hr {
  background-color: #eee;
}
section.about-section h4 {
  font-size: 2.7em;
  line-height: 1.4em;
}

@media (min-width: 1500px) {
  section.projects-section .container-fluid {
    padding: 0 80px;
  }
}
section.projects-section .project .features .badge {
  border-radius: 0;
  font-size: 1.2em;
}
section.projects-section .project .card-title {
  color: #0E2D52;
}
section.projects-section .project .price {
  color: #0A3B9C;
}

section.featured-projects-section .hot-title {
  background-color: #FCAC2F;
  padding: 1.5rem;
  margin: auto;
}
section.featured-projects-section p {
  font-style: italic;
}
section.featured-projects-section .featured-projects .tab-content {
  background-color: #fafafa;
  padding: 0px;
}
section.featured-projects-section .featured-projects .nav-pills button {
  background-color: #fafafa;
  border-radius: 0;
  font-size: 1.1em;
  margin-bottom: 0.6rem;
  padding: 1rem;
}
section.featured-projects-section .featured-projects .nav-pills button .address {
  color: #777;
  font-style: italic;
}
section.featured-projects-section .featured-projects .nav-pills button.active {
  background-color: #fafafa;
  border-right: 3px solid #FCAC2F;
}
@media (max-width: 991.98px) {
  section.featured-projects-section .featured-projects .nav-pills {
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  section.featured-projects-section .featured-projects .nav-pills button {
    background-color: #fff;
    border-bottom: 3px solid #0E2D52;
    margin-left: 5px;
    margin-right: 5px;
    width: 40px;
  }
  section.featured-projects-section .featured-projects .nav-pills button.active {
    background-color: #fff;
    border-right: 0;
    border-bottom: 3px solid #FCAC2F;
  }
  section.featured-projects-section .featured-projects .tab-content {
    padding-right: 0;
  }
}
section.featured-projects-section .featured-projects .top-img .top-details {
  background-color: #0E2D52;
  color: #fff;
}

section.newsletter-section .container .newsletter-content h5 {
  font-size: 1.2em;
  color: #fff;
  text-transform: none;
}
section.newsletter-section .container .newsletter-content p {
  color: #FCAC2F;
}
section.newsletter-section .container .newsletter-content .newsletter-form .input-group-text {
  padding: 0.375rem 0.2rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FCAC2F;
}
section.newsletter-section .container .newsletter-content .newsletter-form .input-group-text i {
  color: #FCAC2F;
  font-size: 1.2em;
}
section.newsletter-section .container .newsletter-content .newsletter-form .form-control {
  color: #FCAC2F;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FCAC2F;
}
section.newsletter-section .container .newsletter-content .newsletter-form .form-control:focus {
  box-shadow: none;
}

/* purgecss start ignore */
.owl-carousel .owl-stage {
  display: flex;
}
.owl-carousel .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  top: 20%;
  left: -80px;
}
.owl-carousel .owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  margin-right: 5px;
}
.owl-carousel .owl-dot span {
  display: none !important;
}
.owl-carousel .owl-dot.active:before {
  content: counter(slides-num);
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  font-weight: bold;
  color: #232323;
  position: absolute;
  left: 0;
  top: 0;
}
.owl-carousel .owl-nav {
  margin-top: 30px !important;
}
.owl-carousel .owl-nav button {
  position: absolute;
}
.owl-carousel .owl-nav button:hover {
  background: #fff !important;
}
.owl-carousel .owl-nav button i {
  color: #232323;
  font-size: 1.8em;
}
.owl-carousel .owl-nav button.owl-prev {
  left: -50px;
}
.owl-carousel .owl-nav button.owl-next {
  right: -50px;
}
.owl-carousel .owl-nav:before {
  content: "";
  position: absolute;
  width: 2px;
  left: -75px;
  top: 30%;
  height: 35%;
  background-color: #232323;
}
@media (max-width: 1400px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 30%;
  }
  .owl-carousel .owl-nav button.owl-next {
    right: 30%;
  }
}
@media (max-width: 1200px) {
  .owl-carousel .owl-nav button.owl-prev {
    left: 23%;
  }
  .owl-carousel .owl-nav button.owl-next {
    right: 23%;
  }
}
@media (max-width: 991px) {
  .owl-carousel .owl-dots {
    margin-top: 30px !important;
  }
  .owl-carousel .owl-nav {
    display: none;
  }
}

.range-slider {
  width: 210px;
}
.range-slider .irs--round .irs-line {
  top: 32px;
}
.range-slider .irs--round .irs-handle {
  border: none;
  background-color: #0E2D52;
  top: 24px;
  width: 18px;
  height: 18px;
}
.range-slider .irs--round .irs-bar {
  top: 32px;
  height: 3px;
  background-color: #FCAC2F;
}
.range-slider .irs--round .irs-from,
.range-slider .irs--round .irs-to {
  font-size: 8px;
  top: 6px;
  background-color: #0E2D52;
  padding: 4px 5px 2px;
}
.range-slider .irs--round .irs-from:before,
.range-slider .irs--round .irs-to:before {
  border-top-color: #0E2D52;
}

/* purgecss end ignore */
/*!rtl:begin:ignore*/
.tparrows {
  position: absolute;
  width: 70px !important;
  height: 70px !important;
  background-color: #FCAC2F !important;
  border-radius: 4px;
}
.tparrows:hover {
  background-color: rgba(252, 172, 47, 0.8) !important;
}
.tparrows.tp-leftarrow:before {
  content: "" !important;
  width: 34px;
  height: 0;
  border-bottom: 4px solid #0E2D52;
  position: absolute;
  left: 20px;
  top: 34px;
}
.tparrows.tp-leftarrow:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 12px solid #0E2D52;
  position: absolute;
  right: 42px;
  top: 27px;
}
.tparrows.tp-rightarrow:before {
  content: "" !important;
  width: 34px;
  height: 0;
  border-bottom: 4px solid #0E2D52;
  position: absolute;
  right: 20px;
  top: 34px;
}
.tparrows.tp-rightarrow:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 12px solid #0E2D52;
  position: absolute;
  left: 42px;
  top: 27px;
}

/*!rtl:end:ignore*/